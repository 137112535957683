import Breadcrumb from "./Breadcrumb";

function Breadcrumbs(params) {

    const path = ['Home', 'Electronics', 'Household', 'Grills', 'Tefal Optigrill']

    return (
        <div className="flex items-center px-40 py-6 gap-2 font-secondary text-sm font-medium text-gray-lidabro-breadcrumb">
            {path.length > 0 ? (
                    path.map((element, index) => <Breadcrumb key={index} label={element} last={path.length-1 == index} />)
                ) : (
                    <Breadcrumb key={0} label={'Home'} last={true} />
            )}
        </div>
    );
}

export default Breadcrumbs;