import { ArrowUpRightSVG, EyeSVG, ItemDetailsShopSVG, RatingStarSVG2, RatingStarSVG3, ReviewsArrowRight, ViewMoreChevronRightSVG } from "../../images/SVGAssets";
import Badge from "../Badge/Badge";
import ItemCard from "../ItemCard/ItemCard";
import ReviewCard from "../ReviewCard/ReviewCard";

function ItemDetails(params) {

    return (
        <div className="flex flex-col gap-8 px-36 py-10 bg-white-lidabro-ligth-gray">
            <div className="flex gap-8">
                <div className="flex flex-col gap-4 rounded-3xl px-10 py-12 min-w-[500px] bg-white">
                    <div className="flex items-center justify-between p-4 rounded-lg bg-gray-lidabro-select-background font-secondary text-base font-medium text-gray-lidabro-breadcrumb">
                        <div className="flex items-start gap-2">
                            <ItemDetailsShopSVG />
                            <p>Sold by GMS Store</p>
                        </div>
                        <Badge  label={<>Visit <ArrowUpRightSVG /></>} 
                                borderColor={'border-gray-lidabro-select-menu-border'}
                                bgColor={'bg-gray-lidabro-select-background'}
                                textColor={'text-gray-lidabro-breadcrumb-bold'} />
                    </div>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4 font-secondary text-base font-semibold text-black-lidabro">
                            <p>Description</p>
                            <p className="text-sm font-normal">The Tefal Optigrill is an intelligent and versatile kitchen appliance, designed to perfectly grill a variety of foods. It comes equipped with six cooking presets and an advanced sensor that adjusts cooking time based on food thickness, ensuring perfectly cooked meals every time.</p>
                        </div>
                        <div className="flex flex-col gap-4 font-secondary text-base font-normal text-black-lidabro">
                            <p className="font-semibold">Details</p>
                            <div className="flex items-center justify-between pb-2 border-b border-[#CDCDCD]">
                                <p>Country of Origin</p>
                                <p className="font-medium">China</p>
                            </div>
                            <div className="flex items-center justify-between pb-2 border-b border-[#CDCDCD]">
                                <p>Gross Weight</p>
                                <p className="font-medium">0.5 kg</p>
                            </div>
                            <div className="flex items-center justify-between pb-2 border-b border-[#CDCDCD]">
                                <p>Dimensions</p>
                                <p className="font-medium">300 x 200 mm</p>
                            </div>
                            <div className="flex items-center justify-between pb-2">
                                <p>EAN</p>
                                <p className="font-medium">7723910-12</p>
                            </div>
                            <p className="font-medium text-gray-lidabro-dark underline">More Details</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-6 p-6 rounded-3xl font-secondary text-sm font-medium text-[#004EEB] text-center">
                        <div className="flex flex-col gap-2">
                            <p>Have specific questions? The seller will show the product. No need to turn on the camera.</p>
                            <p>Get a personal live introduction to the product from the seller Sa-Mo 9:00 AM - 6:00 PM!</p>
                        </div>
                        <button data-eyezon="76" data-eyezon-target="25346" data-eyezon-title="Tefal Grill" data-eyezon-put-in-cart className="flex items-center justify-center gap-2.5 py-4 px-6 rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold">
                            <EyeSVG fill='white' />
                                See live
                        </button>
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-1/2 min-w-[500px]">
                    <div className="flex flex-col gap-4 p-6 border rounded-3xl bg-white border-white font-secondary text-base font-semibold text-black-lidabro">
                        <p>You may also like</p>
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-wrap gap-6 p-2.5">
                                <ItemCard   key={'01'}
                                            image={'/images/item1.png'}
                                            price={'25'}
                                            name={'100 Recipes on Electro Grill'}
                                            category={'Cook Book'} />
                                <ItemCard   key={'02'}
                                            image={'/images/item2.png'}
                                            price={'5'}
                                            name={'Wooden Spatula'}
                                            category={'Kitchen Appliances'} />
                                <ItemCard   key={'03'}
                                            image={'/images/item3.png'}
                                            price={'70'}
                                            name={'Grill Pan'}
                                            category={'Pan'} />
                                <ItemCard   key={'04'}
                                            image={'/images/item4.png'}
                                            price={'70'}
                                            name={'Set for Kitchen 7 items'}
                                            category={'Kitchen Appliances'} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between p-6 border rounded-3xl font-secondary text-sm font-medium text-black-lidabro bg-white border-white">
                        <p>View more products in Grills</p>
                        <ViewMoreChevronRightSVG />
                    </div>
                    <div className="flex justify-between p-6 border rounded-3xl font-secondary text-sm font-medium text-black-lidabro bg-white border-white">
                        <p>View more products in Household</p>
                        <ViewMoreChevronRightSVG />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-6 p-6 rounded-lg max-h-[650px] font-secondary text-base font-semibold">
                <p>Latest reviews</p>
                <div className="flex gap-3">
                    <div className="flex flex-col gap-3 flex-[1_0_0%]">
                        <div className="flex flex-col items-center justify-center gap-3 p-6 font-medium rounded-lg bg-white border-gray-lidabro-select-menu-border">
                            <p className="font-main text-3xl text-black-lidabro">4.9</p>
                            <p className="font-secondary text-base text-black/30">of 125 reviews</p>
                            <div className="flex items-center">
                                <RatingStarSVG2 fill='#FFB547' />
                                <RatingStarSVG2 fill='#FFB547' />
                                <RatingStarSVG2 fill='#FFB547' />
                                <RatingStarSVG2 fill='#FFB547' />
                                <RatingStarSVG3 />
                            </div>
                        </div>
                        <button className="flex items-center justify-center gap-1 px-3 py-2 border rounded-lg lidabro-button-shadow bg-white border-gray-lidabro-select-menu-border font-secondary text-base font-semibold text-[#182230]">
                            <p>Read all reviews</p>
                            <ReviewsArrowRight />
                        </button>
                    </div>
                    <ReviewCard userDisplayName={'Anna T.'} date={'Today'} review={'Best choice of my life.'} />
                    <ReviewCard userDisplayName={'Tamar D.'} date={'22.06.2024'} review={'Easy to wash and doesn’t take to much space.'} />
                    <ReviewCard userDisplayName={'Maor A.'} date={'12.05.2024'} review={'Easy to use and it warms up very quick.'} />
                </div>
            </div>
        </div>
    )
}

export default ItemDetails;