import React, { useMemo, useState } from "react";
import SearchBar from "./SearchBar";
import SearchBarSeller from "./SearchBarSeller"

function SearchBarController(){
    
    return <SearchBar placeholderText={"Find an item"} />;
    // return <SearchBarSeller placeholderText={"Search for an item, order, transaction or person"} />;
}

export default SearchBarController;