import React from "react";

function CategoryItem({category}){
    return (
        <div className="flex rounded-2xl bg-white px-3 py-1 font-secondary text-sm font-medium text-black-lidabro">
            <p>{category}</p>
        </div>
    );
};

export default CategoryItem;