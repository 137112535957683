function SellerCatalogAddItemCategory({props}){
    return (
        <div className='flex items-center justify-between gap-4.5 rounded-lg p-6 bg-white font-secondary text-base font-semibold text-black-lidabro'>
            <div>
                <p className=''>Category: {props.category}, {props.subcategory}</p>
            </div>
            <div>
                <p>Rate: {props.rate}%</p>
            </div>
        </div>
    )
}

export default SellerCatalogAddItemCategory;