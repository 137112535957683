import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchSVG } from "../../../images/SVGAssets";
import { fetchItemSearch } from "../../../functions/fetch";

function SearchBar(params) {
    const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const navigate = useNavigate();

    useEffect(() => {
		if (query) {
			const abortController = new AbortController();
			fetchItemSearch(query, abortController.signal).then((response) => {
				if (response) {
					setResults(response.results);
				}
			});
			return () => {
				abortController.abort();
			};
		}
	}, [query]);

    
    return (
        <div className="w-full flex items-center gap-2 p-4 border border-gray-lidabro-divider rounded-lg bg-white">
            <SearchSVG />
            <input  className="outline-none w-full placeholder:font-secondary placeholder:text-sm placeholder:font-normal placeholder:text-gray-lidabro-dark" 
                    type="search" 
                    placeholder={params.placeholder} />
        </div>
    )
}

export default SearchBar;