import TabElement from "./TabElement";

function TabMenu({activeTab, setActiveTab}){
    function handleClick(id){
        const object = {};
        for (const property in activeTab){
            object[property] = false
        }
        setActiveTab({...object, [id]: true})
    }
    
    return(
        <div className="flex gap-6">
                <div className="flex border-b border-gray-lidabro-divider w-full">
                    <TabElement id={"inStock"} name={"In Stock"} amount={120} key={"inStock"} active={activeTab.inStock} callback={handleClick}/>
                    <TabElement id={"outOfStock"} name={"Out of Stock"} amount={120} key={"outOfStock"} active={activeTab.outOfStock} callback={handleClick}/>
                    <TabElement id={"drafts"} name={"Drafts"} amount={120} key={"drafts"} active={activeTab.drafts} callback={handleClick}/>
                </div>
            </div>
    )
}

export default TabMenu;