import { useState, useEffect } from "react";
import ListButtonWithIcon from "./ListButtonWithIcon";
import ListButton from "./ListButton";
import ListRow from "./ListRow";
import ListHeader from "./ListHeader";
import ListSearch from "./ListSearch";
import { IconSearchSVG, SortSVG, IconFilterSVG } from "../../images/SVGAssets";

function List({list, activeTab}){

    function defineHeader(){
        if (activeTab.inStock){
            return <ListHeader title={"Title"} description={"Description"} status={"Status"} amount={"Amount"} price={"Price per one"} bgColor={""} /> 
        }
        if (activeTab.outOfStock){
            return <ListHeader title={"Title"} description={"Description"} status={"Status"} amount={null} price={"Price per one"} bgColor={""} /> 
        }
        if (activeTab.drafts){
            return <ListHeader title={"Title"} description={"Description"} status={"Recommendation"} amount={null} price={"Price per one"} bgColor={""} /> 
        }
    }

    return (
        <div className="flex flex-col">
            <ListSearch />
            {defineHeader()}
            {list.map((listItem = [])=>{
                return <ListRow key={listItem.id} {...listItem}/>
            })}
        </div>
    )
}

export default List;