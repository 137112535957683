import React, { useEffect, useState } from "react";
import { ThumbUpSVG, GraduateCap, SadFace, StarSVG, RatingStarSVG } from "../../images/SVGAssets";

function ReviewPlate({type, review}){

    function defineIcon(){
        switch (type.icon) {
            case "topPositive":
                return <ThumbUpSVG />
            case "topExpert":
                return <GraduateCap />
            case "topCritical":
                return <SadFace />
        }
    }


    return(
        <div className="w-1/3">
           <div className="flex items-center gap-1 mb-3">
                {defineIcon()}
                <p className="font-main text-xs font-medium text-black-lidabro">{type.name}</p>
           </div>
           <div className="flex flex-col p-3 gap-2 rounded-lg bg-white">
                <div>
                    <div className="flex justify-between">
                        <p className="font-main text-sm font-medium text-black-lidabro">{review.userName}</p>
                        <p className="font-main text-xxs font-medium text-gray-lidabro-divider">{review.date}</p>
                    </div>
                    <div className="flex gap-1">
                        <p className="font-main text-xxxs text-black-lidabro">{review.rating}</p>
                        {Array.from({length: parseInt(review.rating)}).map(()=><RatingStarSVG />)}
                    </div>
                </div>
                <hr className='rounded-sm border-gray-lidabro-button'/>
                <div>
                    <p className="font-main text-xxs font-medium">“{review.text}”</p>
                </div>
                <div>
                    <p className="font-main text-xxs font-medium text-blue-lidabro-header">Read More</p>
                </div>
           </div>
        </div>
    );
}

export default ReviewPlate;