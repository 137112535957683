import { useEffect } from "react";
import { IconMore } from "../../images/SVGAssets";
import ListStatusPlate from "./ListStatusPlate";
import ListCheckbox from "./ListCheckbox";

function ListRow(props){
    
    useEffect(()=>{
        console.log("ListRow Render");
    },[]);
    
    return (
        <div className={`flex items-center justify-between py-3 font-secondary text-sm font-semibold text-black-lidabro ${props.bgColor ? props.bgColor : 'bg-white'}`}>
            <div className="flex items-center justify-start px-3 gap-6 w-2/3">
                <ListCheckbox label={null}/>
                <div className="">
                    {props.imgUrl == null ? null : (
                        <img className="w-14 h-14 object-fill rounded-lg" src={props.imgUrl} alt="" />)
                    }
                </div>
                <div className="flex flex-col gap-3 w-6/12">
                    <div className={`${props.title == null ? " hidden" : null}`}>
                        <p>{props.title}</p>
                    </div>
                    {props.description == null ? 
                        (null):
                        (
                            <div className="text-gray-lidabro-dark font-medium">
                                <p>{props.description}</p>
                            </div>
                        )
                    }             
                </div>
                <div className={`px-3 py-3${props.status == null ? " hidden" : null}`}>
                    <ListStatusPlate status={props.status} />
                </div>
            </div>
            <div className="flex items-center justify-end w-1/3">
                <div className={`w-3/12 px-3 py-3 font-semibold"${props.currentAmount == null ? " hidden" : null}`}>
                    <p>{props.currentAmount}{props.totalAmount == null ? null : ` of ${props.totalAmount}`}</p>
                </div>
                <div className={`w-3/12 px-3 py-3 font-semibold"${props.price == null ? " hidden" : null}`}>
                    <p>{props.price} NIS</p>
                </div>
                <div className="w-1/12">
                    <IconMore />
                </div>
            </div>
        </div>
    )
}

export default ListRow;