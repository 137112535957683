import DeliveryCheckbox from "./DeliveryCheckbox";
import DeliveryIcon from "./DeliveryIcon";

function DeliveryOption({icon, type, price, description, id, isChecked, onChange}) {
    
    return (
        <div className={`box-border flex justify-between p-4 rounded-xl border-2 bg-white ${isChecked ? 'border-[#1570EF]' : 'border-[#EAECF0]'}`}>
            <div className="flex gap-3">
                <DeliveryIcon icon={icon} />
                <div className="flex flex-col font-secondary text-sm font-normal text-gray-lidabro-breadcrumb">
                    <div className="flex gap-1">
                        <p className="font-medium text-gray-lidabro-breadcrumb-bold">{type}</p>
                        <p>{price}</p>
                    </div>
                    {description}
                </div>
            </div>
            <div>
                <DeliveryCheckbox id={id} checked={isChecked} onChange={() => onChange(id)}/>
            </div>
        </div>
    )
}

export default DeliveryOption;