import React, { useEffect, useState } from "react";
import { MapPinSVG } from "../../images/SVGAssets";

function SellerPageLocation(){

    return(
        <div className="flex gap-1.5 text-black-lidabro font-secondary text-sm font-medium">
            <MapPinSVG />
            <p className="font-secondary ">City, Street 00</p>
        </div>
    );
}

export default SellerPageLocation;