function SellerReviewStar({rating, totalReviews, amountThisRatingReview}){
    return(
        <div className="flex items-center gap-1.5">
            <p className="font-main text-xxs font-medium underline text-blue-lidabro-header">{rating} star</p>
            <div className="border w-10/12 bg-white rounded-xl h-1.5">
                <div className="bg-blue-lidabro-selected-tab h-1 rounded-sm" style={{"width": 100 / (totalReviews / amountThisRatingReview) + "%"}}></div>
            </div>
            <p className="font-main text-xxs font-medium text-gray-lidabro-divider">{Math.ceil(100 / (totalReviews / amountThisRatingReview)) + "%"}</p>
        </div>
    )
}

export default SellerReviewStar;