import React, { useEffect, useState } from "react";
import { fetchSetupSubList } from "../../functions/fetch";
import SellerHomeSetupSubListItem from "./SellerHomeSetupSubListItem";

function SellerHomeSetupListItem({setup}){
    let setupSubList = [];
    setupSubList = fetchSetupSubList(setup.id);

    return (
        <div className="flex flex-col justify-center bg-gray-lidabro-row">
            <p className="font-secondary font-semibold text-sm mx-3 my-2">{setup.description}</p>
            <div className="bg-white px-2 py-1">
            {setupSubList.length > 0 ? (
					setupSubList.map((setup) => <SellerHomeSetupSubListItem key={setup.id} setup={setup} />)
				) : (
					<div>Loading...</div>
				)}
            </div>
        </div>
    )
}

export default SellerHomeSetupListItem;