import { useState } from "react";
import { UploadSVG, IconImageExist } from "../../images/SVGAssets";
import ListCheckbox from "../List/ListCheckbox";
import InputOnly from "../SellerCatalog/InputOnly";
import SingleChoice from "../SellerCatalog/SingleChoice";

function SellerCatalogAddItemInfo(){
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [formValues, setFormValues] = useState({});

    function handleDescriptionChange(e){
        setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
        setDescriptionLength(e.target.value.length);
    }
    
    return (
        <div className='flex flex-col gap-3 bg-white p-6 rounded-lg'>
            <InputOnly atributeName={"barcode"} label={"Barcode"} placeholder={"Enter the product's barcode"} />
            <InputOnly atributeName={"name"} label={"Product Name"} required={true} placeholder={"Enter a title..."} tooltip={<p className="font-secondary text-sm font-medium text-gray-lidabro-divider">Max 100 characters</p>} />
            <InputOnly atributeName={"brand"} label={"Brand"} required={true} placeholder={"Enter brand name..."} tooltip={<ListCheckbox label={"No Brand"} />} />
            <SingleChoice atributeName={"origin"} label={"Country of Origin"} placeholder={"Select"} values={"USA"} />
            <div className="flex flex-col gap-1.5">
                <label htmlFor="description" className="font-secondary text-base font-semibold">Description</label>
                <div className="flex flex-col items-end border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                    <textarea id="description" className="w-full outline-none resize-none placeholder:font-main placeholder:text-sm placeholder:font-medium" cols="30" rows="4" maxLength={250} placeholder="Enter a description..." onChange={handleDescriptionChange}></textarea>
                    <p className="font-secondary text-xxs font-medium text-gray-lidabro-divider">{descriptionLength}/250</p>
                </div>
            </div>
            <InputOnly atributeName={"keywords"} label={"Keywords"} placeholder={"Enter the keywords"} />
            <div className="flex flex-col gap-1.5">
                <label htmlFor="images" className="font-secondary text-base font-semibold">Images</label>
                <div className="flex flex-col items-center gap-3 px-3 py-6 border border-dashed rounded-lg text-blue-lidabro-header bg-white-lidabro-ligth-gray">
                    <IconImageExist />
                    <p className="font-main text-sm font-medium text-gray-lidabro-dark">Drag and Drop up to 4 Images</p>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <label htmlFor="price" className="font-secondary text-base font-semibold">Price <span className="text-red-lidabro-important">*</span></label>
                <div className="w-7/12 flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                    <input id="price" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                    <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">NIS / per 1 item</p>
                </div>
            </div>
            <div className="flex items-center justify-between text-blue-lidabro-selected-tab">
                <label htmlFor="price" className="font-secondary text-base font-semibold ">You will earn</label>
                <p className="min-w-fit font-main text-sm font-medium">{0} NIS</p>
            </div>
            <div className="flex items-center justify-between">
                <label htmlFor="amount" className="font-secondary text-base font-semibold">Amount <span className="text-red-lidabro-important">*</span></label>
                <div className="w-7/12 flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                    <input id="amount" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                    <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">pieces</p>
                </div>
            </div>
            <SingleChoice atributeName={"discount"} label={"Add Discount"} placeholder={"Select"} values={""} orientation={"horizontal"}  />
            <div className="flex items-center justify-between">
                <label htmlFor="weight" className="font-secondary text-base font-semibold">Gross Weight</label>
                <div className="w-7/12 flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                    <input id="weight" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                    <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">kg</p>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div>
                    <label htmlFor="dimension" className="font-secondary text-base font-semibold">Dimensions</label>
                    <p className="font-main text-sm font-normal text-gray-lidabro-dark">Height x Weight x Depth</p>
                </div>
                <div className="flex gap-3 w-7/12">
                    <div className="flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                        <input id="weight" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                        <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">cm</p>
                    </div>
                    <div className="flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                        <input id="weight" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                        <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">cm</p>
                    </div>
                    <div className="flex items-end gap-1 border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                        <input id="weight" className="w-full outline-none text-right placeholder:font-main placeholder:text-sm placeholder:font-medium" type="text"/>
                        <p className="min-w-fit font-main text-sm font-medium text-black-lidabro">cm</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerCatalogAddItemInfo;