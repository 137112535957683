import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SellerHomeSetupList from "../../../components/Seller/SellerHomeSetupList";
import QuickActions from "../../../components/Seller/QuickActions";
import QuickActionButton from "../../../components/Controls/QuickActionButton";
import SalesDistribution from "../../../components/Chart/SalesDistribution";
import Sales from "../../../components/Chart/Sales";
import Clients from "../../../components/Chart/Clients";
import Streams from "../../../components/Chart/Streams";

function SellerHome() {
    function getAmountCompletedTasks(){
        return 2
    }

    function getTotalTasks(){
        return 10
    }

    let amountCompletedTasks = getAmountCompletedTasks();
    let totalTasks = getTotalTasks();

    const SetupFinished = false;

    function renderHome(){
        return (
            <div className="flex flex-col p-3 border rounded-lg w-auto mt-7 bg-white gap-3">
                <div>
                    <h4 className="text-black-lidabro font-main text-sm font-semibold">Quick Analytics</h4>
                    <div>
                        
                    </div>
                </div>
                <div className="flex gap-3 h-60 mb-8">
                    <SalesDistribution />
                    <Sales />
                </div>
                <hr className='border-t border-gray-lidabro-divider'/>
                <Clients />
                <hr className='border-t border-gray-lidabro-divider'/>
                <Streams />                
            </div>
        )
    }

    function renderSetup(){
        return (
            <div className="mt-6">
                <div className="flex flex-col gap-3 px-3 py-2.5 bg-white">
                    <div className="flex">
                        <p className="font-main font-semibold text-sm text-black-lidabro w-3/4">Setup guide</p>
                        <p className="font-main font-semibold text-sm text-gray-lidabro w-1/4 text-right">Skip</p>
                    </div>
                    <div>
                        <p className="font-main text-sm text-black-lidabro">Use this personalized guide to get your store up and running.</p>
                    </div>
                    <div className="flex gap-6 items-center">
                        <p className="font-main text-sm text-black-lidabro w-1/5">{amountCompletedTasks} of {totalTasks} tasks are completed</p>
                        <div className="border w-full bg-gray-lidabro-divider rounded-sm h-1">
                            <div className="bg-blue-lidabro-selected-tab h-1 rounded-sm" style={{"width": 100 / (totalTasks / amountCompletedTasks) + "%"}}></div>
                        </div>
                    </div>
                </div>
                <div>
                    <SellerHomeSetupList />
                </div>
            </div>
        )
    }

    return (
        
            <>
                <h3 className="font-main text-2xl mb-6">Home</h3>
                <QuickActions >
                    <QuickActionButton name={"+   Invite Team Member"} background={"blue-lidabro"} textColor={"white"} callback={()=>{}} />
                    <QuickActionButton name={"+  Add an Item"} background={"green-lidabro-neon"} textColor={"black"} callback={()=>{}} />
                </QuickActions>
                {SetupFinished ? renderHome() : renderSetup()}
            </>
    );
}

export default SellerHome;