import React from "react";

function AddItem(){
    return (
        <div className="flex flex-col items-center cursor-pointer bg-green-lidabro-neon px-3 py-2.5 rounded-lg">
            <p className="font-main text-sm font-bold text-black">+ Add an item</p>
        </div>
    );
};

export default AddItem;