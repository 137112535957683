import { Form, redirect } from 'react-router-dom';
import { createShop, createOwner } from '../../functions/fetch'

function SellerCreateShopStep1(){
    
    return (
        <div className='flex flex-col gap-6 px-16 py-6 w-1/3'>
            <h3 className='font-main font-bold text-2xl'>Open your Shop!</h3>
            <Form id="createShopStep1" action="/create-shop/step-1" method='post' className="flex flex-col gap-6 w-full">
                <div className='flex flex-col gap-1.5'>
                    <label htmlFor="companyName" className='font-main text-black-lidabro'>Company Name<span className='text-red-lidabro-important'>*</span></label>
                    <input className="border rounded-lg px-3 py-2.5" type="text" name="companyName" placeholder="Enter your company name" />
                </div>
                <div className='flex flex-col gap-1.5'>
                    <label htmlFor="companyDescription" className='font-main text-black-lidabro'>Description</label>
                    <textarea className="border rounded-lg px-3 py-2.5 resize-none" name="companyDescription"
                        placeholder="Please provide a brief description of your shop below. This description will be visible on your shop's profile. You can also edit this information later" 
                        rows={5}/>
                </div>
                <div className='flex flex-col gap-1.5'>
                    <label htmlFor="companyOwnerFullName" className='font-main text-black-lidabro'>Business owner’s Full Name<span className='text-red-lidabro-important'>*</span></label>
                    <input className="border rounded-lg px-3 py-2.5" type="text" name="companyOwnerFullName" placeholder="Enter full name" />
                </div>
                <div className='flex flex-col gap-1.5'>
                    <label htmlFor="companyOwnerPassportNumber" className='font-main text-black-lidabro'>Company ID<span className='text-red-lidabro-important'>*</span></label>
                    <input className="border rounded-lg px-3 py-2.5" type="text" name="companyOwnerPassportNumber" placeholder="Enter your Company ID" />
                </div>
                <div className='flex flex-col gap-1.5'>
                    <label htmlFor="companyOwnerPhoneNumber" className='font-main text-black-lidabro'>Phone Number<span className='text-red-lidabro-important'>*</span></label>
                    <div className='flex w-full gap-1.5'>
                        <input className="border rounded-lg px-3 py-2.5 w-5/12" type="text" name="companyOwnerPhoneCode" placeholder="+234" />
                        <input className="border rounded-lg px-3 py-2.5 w-7/12" type="text" name="companyOwnerPhoneNumber" placeholder="8023456789" />
                    </div>
                </div>

                <button className="bg-blue-lidabro rounded-lg text-white font-main text-base px-3 py-2.5" type="submit">Next</button>
            </Form>
        </div>
    );
}

const createShopStep1Action = async ({request}) => {
    const formData = await request.formData();
    const newShop = {
        companyName: formData.get('companyName'),
        companyDescription: formData.get('companyDescription'),
    }
    const newOwner = {
        companyOwnerFullName: formData.get('companyOwnerFullName'),
        companyOwnerPassportNumber: formData.get('companyOwnerPassportNumber'),
        companyOwnerPhoneCode: formData.get('companyOwnerPhoneCode'),
        companyOwnerPhoneNumber: formData.get('companyOwnerPhoneNumber'),
    }
    const shop = await createShop(newShop);
    const owner = await createOwner(newOwner);

    return redirect('step-2');
}

export {SellerCreateShopStep1, createShopStep1Action};