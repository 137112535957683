function ListStatusPlate({status}){
    const labelGreen = ["In Stock", "Ready for Sell"]
    const labelYellow = ["Only a few left", "We recommend to add more Photos", "We recommend to add more Description"]
    const labelRed = ["Out of Stock", "Needs more Details"]

    function defineLabelColor(){
        if (labelGreen.includes(status)){
            return "bg-green-lidabro-label-bg"
        }
        if (labelYellow.includes(status)){
            return "bg-yellow-lidabro"
        }
        if (labelRed.includes(status)){
            return "bg-red-lidabro"
        }
        return ""
    }

    return (
        <div className={`w-fit rounded-lg px-3 py-0.5 ${defineLabelColor()}`}>
            <p>{status}</p>
        </div>
    )

}

export default ListStatusPlate;