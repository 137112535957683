import React, { useEffect, useState } from "react";
import SellerHomeSetupListItem from "./SellerHomeSetupListItem";
import { fetchSetupList } from "../../functions/fetch";

function SellerHomeSetupList(){
    let setupList = [];
    setupList = fetchSetupList();
    return (
        <div className="">
            {setupList.length > 0 ? (
					setupList.map((setup) => <SellerHomeSetupListItem key={setup.id} setup={setup} />)
				) : (
					<div>Loading...</div>
				)}
        </div>
    );
}

export default SellerHomeSetupList;