import React from "react";

function ButtonShortTransparent({label, icon, callback, disabled}){
    return (
        <div className={`${disabled ? "opacity-40 " : "hover:shadow-[0_2px_10px_0px_rgba(0,0,0,0.3)] hover:text-gray-lidabro-divider focus:border-white "}flex flex-row items-center justify-center gap-3 cursor-pointer px-3 py-2.5 rounded-lg border border-gray-lidabro-divider shadow-[0_2px_8px_0px_rgba(0,0,0,0.1)] text-white`} 
            onClick={() => {disabled ? null : callback()}}>
            <p className="font-main text-base font-medium leading-5 text-center">{label}</p>
            {icon ? icon : null}
        </div>
    );
};

export default ButtonShortTransparent;