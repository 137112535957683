import { useState } from "react";
import InputOnly from "../SellerCatalog/InputOnly";
import SingleChoiceInput from "../SellerCatalog/SingleChoiceInput";
import SingleChoice from "../SellerCatalog/SingleChoice";
import MultipleChoiceInput from "../SellerCatalog/MultipleChoiceInput";
import MultipleChoice from "../SellerCatalog/MultipleChoice";


function SellerCatalogAddItemInfoAdditional(params) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    return (

        <div className='w-full flex flex-col gap-3 bg-white p-6 rounded-lg'>
            
        </div>
    )
}

export default SellerCatalogAddItemInfoAdditional;