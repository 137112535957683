import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Category 1', 'Category 2', 'Category 3'],
  datasets: [
    {
      label: 'Revenue',
      data: [6, 7, 14],
      backgroundColor: [
        'rgba(225, 79, 97, 1)',
        'rgba(232, 162, 59, 1)',
        'rgba(85, 182, 133, 1)',
      ],
      rotation: -50,
      spacing: 10,
      borderRadius: 9999,
      cutout: "80%"
    },
  ],
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
      legend: {
          position: 'right',
          labels: {
            usePointStyle: true
          }
      }
  }
}

const plugin = {
  beforeDatasetsDraw: function(chart, args, pluginOptions) {
    const { ctx, data } = chart;

    ctx.save();
    const xCoor = chart.getDatasetMeta(0).data[0].x;
    const yCoor = chart.getDatasetMeta(0).data[0].y;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = '10px sans-serif';
    ctx.fillText('Revenue', xCoor, yCoor-20);
    ctx.font = 'bold 20px sans-serif';
    ctx.fillText('110K ₪', xCoor, yCoor);
  }
}

function SalesDistribution(){
    return (
      <div className='w-1/4'>
        <h4 className='text-gray-lidabro-dark'>Sales Distribution</h4>
        <div className='flex flex-col gap-3 p-3 border rounded'>
          <div className='h-32'>
            <Doughnut data={data} plugins={[plugin]} options={options} />
          </div>
          <hr className='border-t border-gray-lidabro-divider'/>
          <div className='flex justify-between'>
            <p className='font-main text-xxs'>Avg. Bill (AOV)</p>
            <div className='flex items-center gap-1.5'>
              <p className='font-secondary text-xxxs text-green-lidabro-label bg-green-lidabro-label-bg border border-green-lidabro rounded-2xl px-1.5 py-1 font-semibold'>+10%</p>
              <p className='font-secondary font-bold text-black-lidabro'>105 ₪ </p>
            </div>
          </div>
          <div className='flex justify-between'>
            <p className='font-main text-xxs'>Client’s Value (CLTV)</p>
            <div className='flex'>
              <p></p>
              <p className='font-secondary font-bold text-black-lidabro'>122 ₪ </p>
            </div>
          </div>
        </div>
      </div>
      
    );
}

export default SalesDistribution;