import React, { useEffect, useState } from "react";
import {CheckboxSVG} from "../../images/SVGAssets"
import {CheckedCheckboxSVG} from "../../images/SVGAssets"

function SellerHomeSetupSubListItem({setup}){
    
    return (
        <div className={`flex items-center rounded-lg pl-3 py-3 gap-3${setup.isDone === 'true' ? ' bg-gray-lidabro-row' : ''}`}>
            {setup.isDone === 'true' ? <CheckedCheckboxSVG /> : <CheckboxSVG />}
            <p className="font-secondary font-semibold text-sm">
                {setup.isDone === 'true' ? <s>{setup.description}</s> : setup.description}
            </p>
        </div>
    )
}

export default SellerHomeSetupSubListItem;