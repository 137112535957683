import { UnionSVG } from "../../images/SVGAssets";

function ListSorter({name}){
    return (
        <div className="flex items-center font-main text-sm font-medium text-gray-lidabro-dark px-3 gap-1.5 hover:text-blue-lidabro-selected-tab">
            <p>{name}</p>
            <UnionSVG />
        </div>
    )
}

export default ListSorter;