import { MoreSVG } from "../../images/SVGAssets";

function ItemPlate({title, price, prevPrice, image}){
    return (
        <div className="flex flex-col bg-white h-44 w-44 rounded-md px-2.5 pt-2.5">
            <div className="mb-1 h-2/3 relative">
                <img className="w-full h-full rounded" src={image} alt=""/>
                <div className="absolute top-1 right-1">
                    <MoreSVG />
                </div>
            </div>
            <div className="flex flex-col gap-1.5">
                <div className="flex items-end gap-1.5">
                    <p className="font-secondary text-xs font-bold text-black-lidabro">{price} NIS</p>
                    {prevPrice ? <p className="font-main text-xxxs font-medium line-through text-gray-lidabro-dark">{prevPrice} NIS</p> : null}
                </div>
                <div className="flex">
                    <p className="font-main text-xxxs font-medium text-gray-lidabro-dark">{title}</p>
                </div>
            </div>
        </div>
    )
}

export default ItemPlate;