function ItemCard({image, price, name, category}) {

    return (
        <div className="flex flex-col w-[185px]">
            <div className="h-[170px]">
                <img className="w-full h-full" src={image} alt={name} />
            </div>
            <div className="flex flex-col py-3 font-secondary text-xs font-medium">
                <p className="text-sm font-semibold text-blue-lidabro-header">$ {price}</p>
                <p className="text-black-lidabro">{name}</p>
                <p className="text-gray-lidabro-dark">{category}</p>
            </div>
        </div>
    )
}

export default ItemCard;