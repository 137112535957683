import { useEffect } from "react";
import { AuthProvider } from "./hoc/AuthProvider";
import Routes from "./hoc";
import './styles/App.css'

function App() {
	useEffect(() => {
		eyeZon("init", {
			businessId:"41",
			region:"prod"
		  });
		eyeZon("on", "PUT_IN_CART_FROM_WIDGET", function(data) { });
		eyeZon("on", "STREAM_RECORD_PUT_IN_CART_FROM_WIDGET", function(data) { });
		eyeZon("on", "PUT_IN_CART_REQUEST_ACCEPTED", function(data) { });
	  }, []);

	return (
	  <AuthProvider>
		<Routes />
	  </AuthProvider>
	);
  }

export default App;