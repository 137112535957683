import { SearchSVG, DownloadSVG } from "../../images/SVGAssets";
import SingleChoice from "../SellerCatalog/SingleChoice";

function ListSearch(){
    return (
        <div className="flex items-center justify-between">
            <div className="flex gap-3 w-8/12">
                <div className="flex items-center gap-3.5 w-6/12 h-11 px-5 border border-gray-lidabro-divider rounded-lg bg-white">
                    <SearchSVG />
                    <input  className="outline-none w-full placeholder:font-secondary placeholder:text-sm placeholder:font-normal placeholder:text-gray-lidabro-dark" 
                            type="text" 
                            placeholder="Search for an item name or SKU number" />
                </div>
                <div className="flex items-center justify-between border rounded-lg border-gray-lidabro-border h-11 px-4 bg-white">
                    <SingleChoice atributeName={"categories"} label={null} placeholder={"All Categories"} values={""} />
                </div>
            </div>
            <div className="p-2.5 border border-gray-lidabro-button rounded-lg bg-white text-gray-lidabro-dark hover:border-gray-lidabro-dark hover:text-black-lidabro">
                <DownloadSVG />
            </div>
        </div>
    )
}

export default ListSearch;