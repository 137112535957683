import React, { useMemo, useState } from "react";
import CompanyLogo from "./CompanyLogo";
import SearchBarController from "./SearchBarController";
import AddItem from "./AddItem";
import NotificationsSeller from "./NotificationsSeller";

function HeaderLoggedSeller(){
    

    return (
        <header className="container flex flex-wrap items-center justify-center h-20 bg-black-lidabro">
            <div className="flex w-full h-12">
                <div className="flex w-2/12 h-full">
                    <CompanyLogo textColor={"text-white"} />
                </div>
                <div className="flex w-5/12">
                    <SearchBarController  />
                </div>
                <div className="flex justify-end items-center w-5/12 h-full">
                    <AddItem />
                    <NotificationsSeller />
                </div>
            </div>
        </header>
    );
}

export default HeaderLoggedSeller;