import React from "react";


function QuickActionButton({name, background, textColor, callback}){

    return (
        <div className={`rounded-lg px-3 h-full flex justify-center items-center bg-${ background ? background : "white"}`} onClick={callback}>
            <p className={`font-main font-bold text-xs text-${textColor}`}>{name}</p>
        </div>  
    );
};

export default QuickActionButton;