import React, { useEffect, useState } from "react";
import CategoryItem from "./CategoryItem";
import { fetchNavCategories } from "../../functions/fetch";
import { MenuSVG } from "../../images/SVGAssets";

function CategoryList(){
    let categories = [
        {'key': '001', 'name': 'Electronics'},
        {'key': '002', 'name': 'Household'},
        {'key': '003', 'name': 'Books & Hobbies'},
        {'key': '004', 'name': 'Pets Supplies'},
    ];
    // categories = fetchNavCategories();
    return (
        <div className="flex flex-1 gap-3 items-center">
            <div className="flex px-3 py-1 items-center gap-2.5 font-secondary text-sm font-medium bg-violete-lidabro-light/20 rounded-2xl h-full">
                <MenuSVG />
                <p>All Categories</p>
            </div>
            {categories.length > 0 ? (
                    categories.map((category) => <CategoryItem key={category.key} category={category.name} />)
                ) : (
                    <div>No categories</div>
            )}
            
        </div>
    );
};

export default CategoryList;