import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import CompanyLogo from "./CompanyLogo";

import SearchBar from "./SearchBar";

import SignUp from "./SignUp";
import LogIn from "./LogIn"
import Control from "./Control";
import { HeartSVG, BoxSVG, BasketSVG, BellSVG } from "../../images/SVGAssets";
import Button from "../Controls/Button";

function Header({ token, setToken }){
    const [openLogin, setOpenLogin] = useState(false);
	const [openRegister, setOpenRegister] = useState(false);

    const username = useMemo(() => {
		if (token) {
			return localStorage.getItem("username");
		}
	}, [token]);
    return (
        <header className="lg:w-full flex items-center lg:gap-8 lg:px-40 lg:py-4">
            <CompanyLogo />
            <SearchBar placeholderText={'Find an item'} />
            <div className="flex justify-evenly items-center gap-3.5">
                <Control name={"Favorites"} icon={<HeartSVG />} />
                <Control name={"Orders"} icon={<BoxSVG />} />
                <Control name={"Basket"} icon={<BasketSVG />} />
                <Control name={"Notifications"} icon={<BellSVG />} />
            </div>
            <div className="w-8 h-8">
                <img className="w-full h-full rounded-full" src="/images/avatar2.png" alt=""/>
            </div>
        </header>
    );
}

export default Header;