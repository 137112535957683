import { XCloseSVG } from "../../images/SVGAssets";

function BannerWithAction({icon, text, additionalText, buttonsArray, bgColor, textColor, additionalTextColor, borderColor}) {
    return (
        <div className={`w-full flex flex-row items-center justify-between p-3 border rounded-xl shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)] ${bgColor} ${textColor} ${borderColor}`}> 
            <div className="flex gap-3">
                {icon}
                <p>{text}</p>
                <p className={`${additionalTextColor}`}>{additionalText}</p>
            </div>
            <div className="flex gap-3 items-center justify-center">
                <div className="flex gap-2">
                    {buttonsArray.map((button) => {return button})}
                </div>
                <XCloseSVG />
            </div>
        </div>
    );
}

export default BannerWithAction;