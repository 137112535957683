import React, { Children } from "react";
import { Outlet } from "react-router-dom";

function QuickActions({ children }){

    return (
        <div className="flex items-center w-fit px-3 py-2 border rounded-lg gap-3 h-11 bg-white">
            <p className=" text-sm font-semibold">Quick Actions</p>
            {Children.map(children, child => child)}
        </div>
    )
}

export default QuickActions;