import React from "react";

function CompanyLogo({textColor = "text-black-lidabro"}){
    return (
        <div className="flex justify-center items-center gap-2">
            <img src="/logo.png" alt="Lidabro" width={50} />
            <h1 className={`font-main text-base2 font-bold ${textColor}`}>Lidabro</h1>
        </div>
    );
}

export default CompanyLogo;