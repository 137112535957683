import React from "react";

function Control({name, icon}){
    return (
        <div className="flex flex-col items-center cursor-pointer">
            {icon}
            <p className="font-main text-xxs text-gray-lidabro">{name}</p>
        </div>
    );
};

export default Control;