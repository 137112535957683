import React, { useEffect, useState } from "react";
import { RefreshClockwiseSVG, PlusInCircleSVG } from "../../images/SVGAssets"
import ItemPlate from "../Misc/ItemPlate";

function SalesCarousel(){
    const items = [
        {'id': '001', 'title': 'Title, Title, Title, Title, Title, Title, Title...', 'price': '995,00', 'prevPrice': '1000,00', 'image': '/images/ItemPic.png'},
        {'id': '002', 'title': 'Title, Title, Title, Title, Title, Title, Title...', 'price': '995,00', 'prevPrice': '1000,00', 'image': '/images/ItemPic.png'},
        {'id': '003', 'title': 'Title, Title, Title, Title, Title, Title, Title...', 'price': '995,00', 'prevPrice': '1000,00', 'image': '/images/ItemPic.png'},
        {'id': '004', 'title': 'Title, Title, Title, Title, Title, Title, Title...', 'price': '995,00', 'prevPrice': '1000,00', 'image': '/images/ItemPic.png'},
        {'id': '005', 'title': 'Title, Title, Title, Title, Title, Title, Title...', 'price': '995,00', 'prevPrice': '1000,00', 'image': '/images/ItemPic.png'},
    ]

    return(
        <div>
            <div className="flex">
                <p className="font-main text-sm font-medium text-black-lidabro px-3 py-2.5">First line items on sale</p>
                <div className="flex items-center bg-white rounded-lg gap-2 mx-3 my-2.5 px-2">
                    <RefreshClockwiseSVG />
                    <p className="font-main text-sm font-semibold text-black-lidabro">Shuffle</p>
                </div>
            </div>
            <div className="border rounded-2xl p-2 flex gap-2 w-fit">
                <div className="bg-white h-44 w-28 border rounded-lg flex flex-col items-center justify-center">
                    <PlusInCircleSVG />
                    <p className="font-main text-xxs font-medium text-gray-lidabro-dark text-center w-3/4">Add an item to the line</p>
                </div>
                {items.map((item) => <ItemPlate title={item.title} price={item.price} prevPrice={item.prevPrice} image={item.image} key={item.id} />)}
            </div>
        </div>
    );
}

export default SalesCarousel;