import { Outlet } from "react-router-dom";
import HeaderLoggedSeller from '../components/Header/HeaderLoggedSeller'
import Sidebar from "../components/Sidebar/Sidebar";

function SellerProfile(){
    return (
        <>
            <HeaderLoggedSeller />
            <div className="flex-grow h-full flex">
                <Sidebar />
                <div className="container px-12 pt-6 bg-gray-lidabro-seller">
                    <Outlet />
                </div>
            </div>
        </>
        
    )
}

export default SellerProfile;