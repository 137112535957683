import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation, redirect } from "react-router-dom";
import { useAuth } from "../../../hook/useAuth";
import { signIn } from "../../../functions/fetch";
import CompanyLogo from "../../../components/Header/CompanyLogo";
import {EyeSVG, EyeSlashSVG} from "../../../images/SVGAssets"
import { formValidation, handleFormValidationResult } from "../../../functions/validation";

const initialState = {
	email: "",
	password: "",
};

const initialErrorState = {
	email: "",
	password: ""
};

let initialIsPasswordVisible = false;

function SellerSignInPage(){
    const { signin } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const fromPage = location.state?.from?.pathname || '/'; 
    const [formValues, setFormValues] = useState(initialState);
    const [formErrorValues, setFormErrorValues] = useState(initialErrorState);
    const [isPasswordVisible, setIsPasswordVisible] = useState(initialIsPasswordVisible);

    function handleChange(e) {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	}

	function handleSubmit(e) {
		e.preventDefault();
        setFormErrorValues(initialErrorState);
        const formValidationResult = formValidation(formValues);
        if (Object.keys(formValidationResult).length == 0){
            signIn(formValues)
                .then((res) => {
                    if (res.status === 200){
                        signin(res.data.token, ()=> navigate("/create-shop/step-1", {replace: true}));
                    }
                })
                .catch((err) => console.log(err)); 
        } else {
            handleFormValidationResult(formValidationResult, formErrorValues, setFormErrorValues);
        }
	}

    function handlePasswordVisible(){
        setIsPasswordVisible(!isPasswordVisible);
    }

    useEffect(()=>{}, [location]);

    return (
        <div className="container flex">
            <div className="flex flex-col items-start justify-center gap-6 mx-16 my-52 w-4/12">
                <h3 className="font-main font-bold text-2xl">Log-in</h3>
                <div className="flex flex-col w-full gap-6">
                    <button className="border rounded-lg px-3 py-2.5 w-full">Log-in with Google</button>
                    <p className="text-center">OR</p>
                </div>
                <form id="signup" action="" onSubmit={handleSubmit} className="flex flex-col gap-6 w-full">
                    <div className="flex flex-col">
                        <label className="font-main font-medium text-base" htmlFor="email">Email</label>
                        <input className="border rounded-lg px-3 py-2.5" type="text" name="email" id="email" placeholder="Enter your email" required={true} value={formValues.email} onChange={handleChange} />
                        <p id="emailError">{formErrorValues.email}</p>
                    </div>
                    <div className="flex flex-col">
                        <label className="font-main font-medium text-base" htmlFor="password">Password</label>
                        <div className="flex items-center border rounded-lg pr-2.5"></div>
                        <input className="w-full h-full px-3 py-2.5 rounded-lg" type={isPasswordVisible ? "text" : "password" } name="password" id="password" placeholder="Enter a password" required={true} value={formValues.password} onChange={handleChange} />
                        <div onClick={handlePasswordVisible}>
                                {isPasswordVisible ? <EyeSlashSVG /> : <EyeSVG /> }
                        </div>
                        <p id="passwordError">{formErrorValues.password}</p>
                    </div>
                    
                    <button className="bg-black-lidabro rounded-lg text-white font-main text-base px-3 py-2.5" type="submit">Log in</button>
                </form>
                <p className="font-main text-xs">You don't have an account yet? <Link to="../become-a-seller" className="font-bold underline" href="">Sign up</Link></p>
            </div>
            <div className="w-8/12 relative">
                <div className="absolute top-9 right-16">
                    <CompanyLogo />
                </div>
                <img className="" src="/images/signup.png" alt="background" />
                <div className="absolute bottom-40 left-16">
                    <div className="flex flex-col gap-6">
                        <p className="font-main font-semibold text-5xl text-white">Unleash Your Selling <br/> Potential with Live Streaming.</p>
                        <p className="font-main font-medium text-xl text-white">Create a free account and get full access to our market place. Connect to your customers and increase your sales by 40%.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerSignInPage;