import axios from 'axios';
const SERVER = getServer();

function getServer(){
	switch (process.env.NODE_ENV) {
		case 'development':
			return 'http://localhost:4000';
		case 'stage':
			return process.env.SERVER_STAGE;
		case 'production':
			return process.env.SERVER_PRODUCTION;
	}
}

export function fetchNavCategories(){
	let categoriesAmount = 6;
    let categories = [];
    for (let i = 1; i < categoriesAmount; i++){
        let category = {'key': `Category${i}`, 'name': `Suggestion ${i}`}
        categories.push(category);
    }
    return categories;
}

export function fetchSetupList(){
	const setupList = [];
	setupList.push({
		id: '001',
		name: 'About',
		description: 'Add Information about the shop'
	});
	setupList.push({
		id: '002',
		name: 'Members',
		description: 'Add Team Members and Streamers'
	});
	setupList.push({
		id: '003',
		name: 'Stream',
		description: 'Set Stream Settings'
	});
	setupList.push({
		id: '004',
		name: 'Bank',
		description: 'Add Bank Account'
	});

	return setupList;
}

export function fetchSetupSubList(listId){
	const setupList = [];
	switch (listId) {
		case '001':
			setupList.push({
				id: '006',
				name: 'Description',
				description: 'Write shop\'s description',
				isDone: 'true'
			});
			setupList.push({
				id: '007',
				name: 'Logo',
				description: 'Upload Logo',
				isDone: 'false'
			});
			setupList.push({
				id: '008',
				name: 'PickUpPoint',
				description: 'Set up pickup point',
				isDone: 'false'
			});
			setupList.push({
				id: '009',
				name: 'Streaming',
				description: 'Set Streaming Hours',
				isDone: 'false'
			});
		case '002':
			setupList.push({
				id: '010',
				name: 'InviteEditors',
				description: 'Invite Team Members to collaborate and assist in keeping your shop up-tp-date',
				isDone: 'false'
			});
			setupList.push({
				id: '011',
				name: 'InviteStreamers',
				description: 'Add performers who will showcase catalog assortment during live streams',
				isDone: 'false'
			});
		case '003':
			setupList.push({
				id: '012',
				name: 'Language',
				description: 'Add more language to attract larger customer range',
				isDone: 'false'
			});
			setupList.push({
				id: '013',
				name: 'InitialExperience',
				description: 'Create more personalised service',
				isDone: 'false'
			});
			setupList.push({
				id: '015',
				name: 'Widget',
				description: 'Set up customer interaction tools',
				isDone: 'false'
			});
		case '004':
			setupList.push({
				id: '016',
				name: 'BankAccount',
				description: 'Link your bank account to enable us to deposit the earnings from your sold items directly',
				isDone: 'false'
			});
		
			
	}

	return setupList;
}

export function fetchCreateShopCurrentStep(){
	return 1;
}

export async function fetchItemByName(id, signal){
	categoriesAmoun = 5;
    categories = [];
    for (let i = 1; i < categoriesAmoun; i++){
        category = {'key': `Category${i}`, 'category': `Category ${i}`}
        categories.push(category);
    }
}

export async function signUp(data, controller){
	const searchObject = {
		api: SERVER,
		endpoint: `users/`,
	};
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	
	data.controller = controller;
	try {
		const response = await axios.post(url.href, data);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function signIn(data){
	const searchObject = {
		api: SERVER,
		endpoint: `users/signin`,
	};
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	try {
		const response = await axios.post(url.href, data);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function isShopCreated(userId){
	const searchObject = {
		api: SERVER,
		endpoint: `shops/user/${userId}`,
	};
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	try {
		const response = await axios.get(url.href);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function createShop(data){
	const searchObject = {
		api: SERVER,
		endpoint: `shops/`,
	};
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	try {
		const response = await axios.post(url.href, data);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function createOwner(data){
	const searchObject = {
		api: SERVER,
		endpoint: `persons/`,
	};
	data.role = 'owner';
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	try {
		const response = await axios.post(url.href, data);
		return response;
	} catch (err) {
		throw err;
	}
}

export async function fetchItemSearch(data){
	const searchObject = {
		api: SERVER,
		endpoint: `items/`,
	};
	const url = new URL(`${searchObject.endpoint}`, `${searchObject.api}`);
	try {
		const response = await axios.post(url.href, data);
		return response;
	} catch (err) {
		throw err;
	}
}

export function fetchSimilarItemsByCategory(category){
	const items = [];

	switch (category) {
		case 'grills':
			items.push({
				'id': '0001',
				'image': '/images/itemgrills1.png',
                'price': '650',
                'name': 'LG XL Gas Grill',
                'category': 'Grills'
			});
			items.push({
				'id': '0002',
				'image': '/images/itemgrills2.png',
                'price': '650',
                'name': 'Electro Grill',
                'category': 'Grills'
			});
			items.push({
				'id': '0003',
				'image': '/images/itemgrills3.png',
                'price': '650',
                'name': 'Grill Pan 25 cm',
                'category': 'Pan'
			});
			items.push({
				'id': '0004',
				'image': '/images/itemgrills4.png',
                'price': '650',
                'name': 'Cast Iron Pan 20 cm',
                'category': 'Pan'
			});
			items.push({
				'id': '0005',
				'image': '/images/itemgrills5.png',
                'price': '650',
                'name': 'Outdoor Open Flame Grill',
                'category': 'Grill'
			});
			break;
		case 'household':
			items.push({
				'id': '0011',
				'image': '/images/itemhousehold1.png',
                'price': '25',
                'name': '100 Recipes on Electro Grill',
                'category': 'Cook Book'
			});
			items.push({
				'id': '0012',
				'image': '/images/itemhousehold2.png',
                'price': '5',
                'name': 'Wooden Spatula',
                'category': 'Kitchen Appliances '
			});
			items.push({
				'id': '0013',
				'image': '/images/itemhousehold3.png',
                'price': '70',
                'name': 'Glass Cap 300ml',
                'category': 'Tableware'
			});
			items.push({
				'id': '0014',
				'image': '/images/itemhousehold4.png',
                'price': '1370',
                'name': 'Vacuum Cleaner',
                'category': 'Home Electronics'
			});
			items.push({
				'id': '0015',
				'image': '/images/itemhousehold5.png',
                'price': '70',
                'name': 'Grill Pan',
                'category': 'Pan'
			});
			break;
	}

	return items;
}