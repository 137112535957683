import Select, { components } from 'react-select'
import classNames from 'classnames';
import { ChevronDownSelectSVG, SelectCheckmarkSVG } from '../../images/SVGAssets';
import { AtributeRequired } from "./SellerCatalogHelper";

const ROW_HEIGHT = 44; // Height of a single row, adjust as needed
const MAX_ROWS = 5; // Maximum number of rows to display before showing scrollbar

const selectStyle = {
    indicatorSeparator: () => 'hidden',
    control: ({ isDisabled, isFocused }) =>
        classNames(
          'font-secondary',
          'text-base', 
          'font-normal',
          'leading-6',
          'border border-gray-lidabro-divider rounded-lg px-3.5 py-2.5',
          isFocused && '!border-blue-lidabro-select-focused shadow-select-shadow'
        ),
    placeholder: ({ isFocused }) => 
        classNames(
            'text-gray-lidabro-placeholder',
            isFocused && 'hidden'
        ),
    menu: () => classNames(
        'flex',
        'flex-column',
        'flex-start',
        'flex-[1_0_0]',
        'bg-white',
        'py-1',
        'px-1.5',
        'rounded-lg',
        'border',
        'border-gray-lidabro-select-menu-border'
    ),
    menuList: () => classNames(
        'w-full',
        'scrollbar',
        'scrollbar-w-2',
        'scrollbar-thumb-rounded-full', 
        'scrollbar-track-rounded-full',
        'scrollbar-thumb-gray-lidabro-select-menu-border'
    ),
    valueContainer: () => classNames(
        'text-black-lidabro-select-text',
        'font-medium'
    ),
    option: () => classNames(
        '!flex',
        'flex-row',
        'flex-[1_0_0]',
        'justify-between',
        'items-center',
        'px-2',
        'py-2.5',
        'rounded-md',
        'hover:bg-gray-lidabro-select-background',
    )
}

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate1', label: 'Chocolate1' },
    { value: 'strawberry1', label: 'Strawberry1' },
    { value: 'vanilla1', label: 'Vanilla1' }
  ]

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownSelectSVG />
        </components.DropdownIndicator>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props}>
            {props.label}
            {props.isSelected ? <SelectCheckmarkSVG /> : null}
        </components.Option>
    )
}

function SingleChoice({atributeName, label, placeholder, required, values, onChange, orientation = "vertical"}) {

    function isVertical(orientation) {
        if (orientation == "vertical") {
            return true
        }
        return false;
    }

    return (
        <div className={ isVertical(orientation) ? "flex flex-col gap-1.5" : "flex items-center justify-between"}>
            <label htmlFor={atributeName} className="font-secondary text-base font-semibold">{label}{required ? " " : null}{required ? AtributeRequired() : null}</label>
            {isVertical(orientation) ? 
                <Select unstyled={true}
                    options={options}
                    placeholder={placeholder}
                    classNames={selectStyle}
                    components={{ DropdownIndicator, Option }}
                    maxMenuHeight={ROW_HEIGHT * MAX_ROWS}
                /> : 
                <div className="w-7/12">
                    <Select unstyled={true}
                        options={options}
                        placeholder={placeholder}
                        classNames={selectStyle}
                        components={{ DropdownIndicator, Option }}
                        maxMenuHeight={ROW_HEIGHT * MAX_ROWS}
                    />
                </div> 
                }
            
        </div>
    )
}

export default SingleChoice;