import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./fonts/Gill-Sans-MT.ttf";
import "./fonts/Itim-Regular.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Montserrat.ttf"


ReactDOM.createRoot(document.getElementById("root")).render(
<React.StrictMode> 
	<App />
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals