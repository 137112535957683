import React, { useEffect, useState } from "react";

function CategoryPlate({name}){

    return(
        <div className="px-3 py-1 rounded-lg w-fit border border-white">
            <p className="text-white font-secondary text-sm">{name}</p>
        </div>
    );
}

export default CategoryPlate;