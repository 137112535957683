import React, { useEffect, useState } from "react";
import SellerReviewStar from "./SellerReviewStar";
import ReviewPlate from "../Misc/ReviewPlate";
import { ArrowRightSVG, ThumbUpSVG, GraduateCap, SadFace } from "../../images/SVGAssets";

function Reviews(){
    const reviews = [{'avatar': 'path'}];

    return(
        <div className="w-1/2">
            <div className="flex flex-col gap-3 px-6 py-7">
                <div className="flex justify-between">
                    <p className="font-main text-sm font-medium text-black-lidabro">Customer reviews</p>
                    <div className="flex gap-2.5 items-center rounded-lg bg-violete-lidabro-light px-3">
                        <p className="font-main text-xs font-medium">Read All Reviews</p>
                        <ArrowRightSVG />
                    </div>
                </div>
                <hr className='rounded-sm border-gray-lidabro-button'/>
                <SellerReviewStar rating={5} totalReviews={100} amountThisRatingReview={85}/>
                <SellerReviewStar rating={4} totalReviews={100} amountThisRatingReview={10}/>
                <SellerReviewStar rating={3} totalReviews={100} amountThisRatingReview={3}/>
                <SellerReviewStar rating={2} totalReviews={100} amountThisRatingReview={1}/>
                <SellerReviewStar rating={1} totalReviews={100} amountThisRatingReview={1}/>
                <hr className='rounded-sm border-gray-lidabro-button'/>
                <div className="flex gap-3">
                    <ReviewPlate 
                            type={{ "icon": "topPositive", 
                                    "name": "Top positive review"}} 
                            review={{   "userName": "Yoel S.",
                                        "date": "10.07.2022",
                                        "rating": "5.0",
                                        "text": "Really professional and friendly consultants."}}/>
                    <ReviewPlate 
                            type={{ "icon": "topExpert", 
                                    "name": "Top expert review"}} 
                            review={{   "userName": "Yoel S.",
                                        "date": "10.07.2022",
                                        "rating": "4.0",
                                        "text": "Really professional and friendly consultants."}}/>
                    <ReviewPlate 
                            type={{ "icon": "topCritical", 
                                    "name": "Top critical review"}} 
                            review={{   "userName": "Yoel S.",
                                        "date": "10.07.2022",
                                        "rating": "3.0",
                                        "text": "Really professional and friendly consultants."}}/>
                </div>
            </div>
        </div>
        
    );
}

export default Reviews;