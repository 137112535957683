import React from "react";
import {BellSVG} from "../../images/SVGAssets";

function Notifications(){
    return (
        <div className="flex items-center cursor-pointer bg-black-lidabro-light px-3 py-2.5 rounded-lg">
            <BellSVG color='white' />
            <p className="font-main text-xxs text-white">Notifications</p>
        </div>
    );
};

export default Notifications;