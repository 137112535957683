import { useState } from "react";
import { BasketSVG2, DeliveryFastSVG, DeliverySelfPickUpSVG, DeliveryStandardSVG, DotSVG, EyeSVG, HeartSVG, MapPinSVG3, RatingStarSVG2, RatingStarSVG3, ShareSVG } from "../../images/SVGAssets";
import Badge from "../Badge/Badge";
import ButtonIcon from "../Controls/ButtonIcon";
import DeliveryOption from "../DeliveryOption/DeliveryOption";
import ItemMedia from "./ItemMedia";

function ItemInfo(params) {
    const [selectedOption, setSelectedOption] = useState('standard');
    const [count, setCount] = useState(1);

  const increment = () => {
    setCount(prevCount => prevCount + 1);
  };

  const decrement = () => {
    setCount(prevCount => {
      if (prevCount > 1) {
        return prevCount - 1;
      } else {
        return prevCount;
      }
    });
  };

    const handleOptionChange = (optionId) => {
        setSelectedOption(optionId);
    };

    return (
        <div className="flex pt-5 pb-10 pr-24 gap-8 bg-white">
            <ItemMedia />
            <div className="flex flex-col gap-4 p-6 bg-gray-lidabro-select-background rounded-[20px]">
                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2 font-secondary text-lg font-semibold text-black">
                            <p>Tefal</p>
                            <p>Optigrill GC712</p>
                            <p className="text-sm font-medium text-gray-lidabro-article">#25949312</p>
                        </div>
                        <div className="flex">
                            <ButtonIcon icon={<HeartSVG />} />
                            <ButtonIcon icon={<ShareSVG />} />
                        </div>
                    </div>
                    <div className="flex gap-2 font-secondary text-sm font-medium text-black-lidabro-review">
                        <p>4.9</p>
                        <div className="flex items-center">
                            <RatingStarSVG2 fill='#FFB547' />
                            <RatingStarSVG2 fill='#FFB547' />
                            <RatingStarSVG2 fill='#FFB547' />
                            <RatingStarSVG2 fill='#FFB547' />
                            <RatingStarSVG3 />
                        </div>
                        <p className="text-gray-lidabro-article">125 reviews</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2.5">
                        <Badge label={'-15%'} textColor={'text-[#026AA2]'} borderColor={'border-[#B9E6FE]'} bgColor={'bg-[#F0F9FF]'} />
                        <Badge label={'HIT'} textColor={'text-[#004EEB]'} borderColor={'border-[#B2CCFF]'} bgColor={'bg-[#EFF4FF]'} />
                    </div>
                    <div className="flex gap-2 font-main text-2xl font-semibold text-black-lidabro">
                        <p>₪ 976,6</p>
                        <p className="font-secondary text-xl font-medium line-through text-[#A0A0A0]">₪ 1149</p>
                    </div>
                </div>
                <div className="flex flex-col p-4 gap-4">
                    <button data-eyezon="76" data-eyezon-target="25346" data-eyezon-title="Tefal Grill" data-eyezon-put-in-cart className="flex items-center justify-center gap-2.5 py-4 px-6 rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold">
                        <EyeSVG fill='white' />
                            See live
                    </button>
                    <div className="flex gap-2 items-center font-secondary text-sm font-medium text-gray-lidabro-select-remove">
                        <div className="flex gap-1 items-center">
                            <DotSVG />
                            <p className="text-[#47CD89]">Available</p>
                        </div>
                        <p>Sa-Mo 9:00 AM - 6:00 PM Jerusalem Time</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <div className="flex gap-2 items-center font-secondary text-base font-medium text-gray-lidabro-breadcrumb">
                        <MapPinSVG3 />
                        <p>Delivery to <span className="underline">Tel Aviv-Yafo, Israel</span></p>
                    </div>
                    <DeliveryOption id={'standard'}
                                    isChecked={selectedOption === 'standard'} 
                                    onChange={handleOptionChange} 
                                    key={'1'} 
                                    icon={<DeliveryStandardSVG />} 
                                    type={'Standard Delivery'} 
                                    price={'+15₪'} 
                                    description={'Delivery on Wednesday, June 13'} />
                    <DeliveryOption id={'fast'}
                                    isChecked={selectedOption === 'fast'} 
                                    onChange={handleOptionChange}
                                    key={'2'} 
                                    icon={<DeliveryFastSVG />} 
                                    type={'Fast Delivery'} 
                                    price={'+25₪'} 
                                    description={'Delivery Today, June 13 18:00-19:00'} />
                    <DeliveryOption id={'pickup'}
                                    isChecked={selectedOption === 'pickup'} 
                                    onChange={handleOptionChange}
                                    key={'3'} 
                                    icon={<DeliverySelfPickUpSVG />} 
                                    type={'Self Pick Up in the Shop'} 
                                    price={'Free'} 
                                    description={'Zeev Jabotinsky St 132, Tel Aviv-Yafo'} />
                </div>
                <div className="flex gap-4">
                    <div className="flex items-center gap-6 px-3 py-2.5 font-secondary text-sm font-medium text-gray-lidabro-dark">
                        <button onClick={decrement}>-</button>
                        <p className="font-semibold text-black-lidabro">{count}</p>
                        <button onClick={increment}>+</button>
                    </div>
                    <button className="flex flex-1 items-center justify-center gap-2.5 rounded-lg px-6 py-4 bg-gray-lidabro-breadcrumb lidabro-button-shadow font-secondary text-lg font-semibold text-white/90">
                        <BasketSVG2 />
                        <p>Add to basket</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ItemInfo;