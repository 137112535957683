function ItemMedia(params) {

    return (
        <div className="flex flex-col flex-1 gap-12 items-center justify-center">
            <div>
                <img src="/images/grill1.png" alt="Tefal Optigrill GC712" />
            </div>
            <div className="flex gap-6 items-center justify-center">
                <img className="w-auto h-auto" src="/images/grill2.png" alt="Tefal Optigrill GC712" />
                <img className="w-auto h-auto" src="/images/grill3.png" alt="Tefal Optigrill GC712" />
                <img className="w-auto h-auto" src="/images/grill4.png" alt="Tefal Optigrill GC712" />
                <img className="w-auto h-auto" src="/images/grill5.png" alt="Tefal Optigrill GC712" />
            </div>
        </div>
    )
}

export default ItemMedia;