import React, { useMemo, useState } from "react";
import { NavLink, redirect } from "react-router-dom";
import SellerSidebarButton from "../Controls/SellerSidebarButton";
import { HouseSVG, ShopSVG, TagSVG, UsersSVG, LiveSVG, InboxSVG, CreditCardSVG, MegaphoneSVG, ChatInfoSVG, SettingsSVG, LogoutSVG } from "../../images/SVGAssets";
import { catalogChildren, teamChildren, streamManagementChildren, ordersChildren, promotionsChildren, settingsChildren } from "../../functions/lists"

function Sidebar({}){
    
    return (
        <div className="w-64 p-6 bg-gray-lidabro-sidebar flex flex-col justify-between">
            <div>
                <div className="flex pb-6 border-b border-gray-lidabro-divider">
                    <div className="w-11 h-11">
                        <img className="w-full h-full rounded-full" src="/images/Avatar.png" alt=""/>
                    </div>
                    <div className="ml-3 font-main text-sm">
                        <p className="text-blue-lidabro-username font-semibold">Max</p>
                        <p className="text-black-lidabro text-xxs font-medium">ADMIN</p>
                    </div>
                </div>
                <div className="py-6">
                    <SellerSidebarButton name={'Home'} icon={<HouseSVG />} callback={()=>redirect('/profile')} />
                    <SellerSidebarButton name={'Your Shop'} icon={<ShopSVG />} callback={()=>redirect('/shop')} />
                    <SellerSidebarButton name={'Your Catalog'} icon={<TagSVG />} callback={()=>redirect('/catalog')} children={catalogChildren} />
                    <SellerSidebarButton name={'Your Team'} icon={<UsersSVG />} callback={()=>redirect('/team')} children={teamChildren} />
                    <SellerSidebarButton name={'Stream Management'} icon={<LiveSVG />} callback={()=>redirect('/stream-management')} children={streamManagementChildren} />
                    <SellerSidebarButton name={'Orders'} icon={<InboxSVG />} callback={()=>redirect('/orders')} children={ordersChildren} />
                    <SellerSidebarButton name={'Payment'} icon={<CreditCardSVG />} callback={()=>redirect('/payment')} />
                    <SellerSidebarButton name={'Promotions'} icon={<MegaphoneSVG />} callback={()=>redirect('/promotions')} children={promotionsChildren} />
                </div>
            </div>
            <div>
                <SellerSidebarButton name={'Disputes'} icon={<ChatInfoSVG />} callback={()=>redirect('/disputes')} />
                {/* <SellerSidebarButton name={'Settings'} icon={<SettingsSVG />} callback={()=>redirect('/settings')} children={settingsChildren} /> */}
                <SellerSidebarButton name={'Logout Account'} icon={<LogoutSVG />} callback={()=>redirect('/logout')} />
            </div>
        </div>
    );
}

export default Sidebar;