import React, { useEffect, useState } from "react";
import CategoryPlate from "../Misc/CategoryPlate"

function SellerPageCategoryList(){

    const categories = [{'id': '0001', 'name': 'Beauty'}, {'id': '0002', 'name': 'Home Supplies'}];

    return(
        <div className="flex gap-2 items-end">
            {categories.length > 0 ? (
					categories.map((category) => <CategoryPlate key={category.id} 
                                                                name={category.name}  />)
				) : (<div></div>)}
        </div>
    );
}

export default SellerPageCategoryList;