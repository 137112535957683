import { useState, useEffect } from "react";
import TabMenu from "../../../components/TabMenu/TabMenu";
import List from "../../../components/List/List";

const defaultActiveTab = {
    "inStock": true,
    "outOfStock": false,
    "drafts": false,
}

const list = [
    {
        "id":"001",
        "imgUrl": "/images/ItemPic2.png",
        "title": "Item and Item",
        "description": "Description",
        "status": "Out of Stock",
        "currentAmount": 0,
        "totalAmount": 22,
        "criticalAmount": 5,
        "price": 225
    },
    {
        "id":"002",
        "imgUrl": "/images/ItemPic2.png",
        "title": "Another Item and Item",
        "description": "Another Description",
        "status": "Only a few left",
        "currentAmount": 5,
        "totalAmount": 22,
        "criticalAmount": 5,
        "price": 230
    },
    {
        "id":"003",
        "imgUrl": "/images/ItemPic2.png",
        "title": "Another Another Item and Item",
        "description": "Another Another Description",
        "status": "In Stock",
        "currentAmount": 20,
        "totalAmount": 22,
        "criticalAmount": 5,
        "price": 250
    }
];

const listOutOfStock = [
    {
        "id":"001",
        "imgExist": false,
        "title": "Item and Item",
        "description": "Description",
        "status": "Out of Stock",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 225
    },
    {
        "id":"002",
        "imgExist": true,
        "title": "Another Item and Item",
        "description": "Another Description",
        "status": "Out of Stock",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 230
    },
    {
        "id":"003",
        "imgExist": true,
        "title": "Another Another Item and Item",
        "description": "Another Another Description",
        "status": "Out of Stock",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 250
    }
];

const listDrafts = [
    {
        "id":"001",
        "imgExist": false,
        "title": "Item and Item",
        "description": "Description",
        "status": "Ready for Sell",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 225
    },
    {
        "id":"002",
        "imgExist": true,
        "title": "Another Item and Item",
        "description": "Another Description",
        "status": "Needs more Details",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 230
    },
    {
        "id":"003",
        "imgExist": true,
        "title": "Another Another Item and Item",
        "description": "Another Another Description",
        "status": "We recommend to add more Photos",
        "currentAmount": null,
        "totalAmount": null,
        "criticalAmount": null,
        "price": 250
    }
];

function SellerYourCatalog(){
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [currentList, setCurrentList] = useState(list);

    useEffect(() => {
        if (activeTab.inStock){
            setCurrentList(list);
        }
        if (activeTab.outOfStock){
            setCurrentList(listOutOfStock);
        }
        if (activeTab.drafts){
            setCurrentList(listDrafts)
        }
    }, [activeTab]);
    

    return(
        <div className="flex flex-col gap-6">
            <div className="">
                <h3 className="font-main text-2xl">Your Catalog</h3>
            </div>
            <div className="flex gap-6">
                <TabMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div>
                <List activeTab={activeTab} list={currentList} />
            </div>
        </div>
    )
}

export default SellerYourCatalog;