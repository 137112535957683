function SellerAboutControlButton({name, callback, bgColor, textColor}){
    return (
        <div 
            className={`px-3 py-1 ${bgColor ? "bg-" + bgColor : "bg-white/5"} text-${textColor ? textColor : "white"} rounded-lg font-main text-sm cursor-pointer w-fit`}
            onClick={callback}
        >
            <p>{name}</p>
        </div>
    );
}

export default SellerAboutControlButton;