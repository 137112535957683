import { RatingStarSVG2 } from "../../images/SVGAssets";

function ReviewCard({userDisplayName, date, review, rate}) {

    return (
        <div className="flex flex-col flex-[1_0_0%] justify-between rounded-lg min-w-[200px] p-6 bg-white">
            <div className="flex flex-col font-secondary text-base font-semibold text-black-lidabro">
                <p>{userDisplayName}</p>
                <p className="font-normal text-black/30">{date}</p>
            </div>
            <p className="font-secondary text-sm font-normal text-black-lidabro">{review}</p>
            <div className="flex items-center">
                <RatingStarSVG2 fill='#FFB547' />
                <RatingStarSVG2 fill='#FFB547' />
                <RatingStarSVG2 fill='#FFB547' />
                <RatingStarSVG2 fill='#FFB547' />
                <RatingStarSVG2 fill='#FFB547' />
            </div>
        </div>
    )
}

export default ReviewCard;