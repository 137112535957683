import { LiveSVG } from "../../images/SVGAssets";

function SellerLiveStream(){
    return (
        <div className="flex gap-1.5 font-secondary text-sm font-medium">
            <LiveSVG />
            <p>Add Live Stream Hours</p>
        </div>
    );
}

export default SellerLiveStream;