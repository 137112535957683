import React, { useState } from 'react';
import FooterLogo from './FooterLogo';
import { FacebookSVG, InstagramSVG, TiktokSVG, TwitterSVG } from '../../images/SVGAssets';

function Footer(){

    return (
        <div className='flex flex-col gap-6 px-40 py-[104px] bg-gray-lidabro-placeholder'>
            <div className='flex gap-[60px]'>
                <div className='w-1/3 flex flex-col gap-6 font-secondary text-sm font-medium text-white'>
                    <FooterLogo />
                    <p>Lidabro is a new, unique, live shopping platform that connects shoppers with sellers in real time.</p>
                </div>
                <div className='flex gap-12 w-2/3'>
                    <div className='w-1/2 flex flex-col gap-4 font-secondary text-sm font-normal text-white'>
                        <p className='text-base font-semibold'>Categories</p>
                        <p>Electronics</p>
                        <p>Books & Hobbies</p>
                        <p>Pets Supplies</p>
                        <p>Sport</p>
                    </div>
                    <div className='w-1/2 flex flex-col gap-4 font-secondary text-sm font-normal text-white'>
                        <p className='text-base font-semibold'>Services</p>
                        <p>Bonus program</p>
                        <p>Gift cards</p>
                        <p>Service Agreement</p>
                        <p>About Us</p>
                    </div>
                </div>
            </div>
            <div className='flex gap-10'>
                <TwitterSVG />
                <FacebookSVG />
                <TiktokSVG />
                <InstagramSVG />
            </div>
        </div>
    )
}

export default Footer;