import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    elements: {
        line: {
            tension: 0.4
        }
    }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
      {
        label: 'Streams',
        data: labels.map(() => Math.ceil(Math.random() * 1000)),
        borderColor: 'rgba(1, 247, 168, 1)',
        backgroundColor: 'rgba(191, 255, 232, 0.5)',
        // backgroundColor: (context) => {
        //   const { ctx, chartArea: {top, bottom} } = context.chart;
        //   const gradient = ctx.createLinearGradient(0, top, 0, bottom);
        //   gradient.addColorStop(0, "rgba(191, 255, 232, 1)");
        //   gradient.addColorStop(1, "rgba(228, 255, 249, 0)");
        //   return gradient;
        // },
        fill: 'start'
      }
    ],
  };

function Streams(){

    return (
        <div>
          <h4 className='text-gray-lidabro-dark'>Streams</h4>
          <div className='border rounded h-full'>
              <Line options={options} data={data} />
          </div>
        </div>
    );
}

export default Streams;