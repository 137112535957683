import CompanyLogo from "./CompanyLogo";

function HeaderEmptySeller(){
    

    return (
        <header className="container flex flex-wrap items-center justify-center h-20 bg-black-lidabro">
            <div className="flex w-full h-12">
                <div className="flex w-2/12 h-full">
                    <CompanyLogo textColor={"text-white"} />
                </div>
            </div>
        </header>
    );
}

export default HeaderEmptySeller;