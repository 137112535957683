import Location from "./Location";
import CategoryList from "./CategoryList";
import BecomePartner from "./BecomePartner";

function NavBar() {

    return (
        <div className="flex gap-6 w-full bg-gray-lidabro-placeholder px-10 py-2 text-white items-center">
            <Location />
            <CategoryList />
            <BecomePartner />
        </div>
    );
}

export default NavBar;