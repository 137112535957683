import ItemCard from "../ItemCard/ItemCard";
import { fetchSimilarItemsByCategory } from "../../functions/fetch";
import { MoreSimilarRightArrow } from "../../images/SVGAssets";

function ItemsSimilar({category}) {
    
    function capitalizeFirstLetter(string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const items = fetchSimilarItemsByCategory(category);

    return (
        <div className="flex flex-col gap-4.5 px-[150px] py-10 border-b border-gray-lidabro-border-2 font-secondary text-lg font-medium text-black-lidabro">
            <p>More in {capitalizeFirstLetter(category)}</p>
            <div className="flex gap-6 p-2.5 relative">
                {items.length > 0 ? (
                    items.map((item) => <ItemCard   key={item.id} 
                                                    image={item.image}
                                                    price={item.price}
                                                    name={item.name}
                                                    category={item.category} />)
                ) : (null)}
                <div className="absolute right-[-50px] top-[70px]">
                    <MoreSimilarRightArrow />
                </div>
            </div>
        </div>
    )
}

export default ItemsSimilar;