import React, { useEffect, useState } from "react";
import SellerPageCategoryList from "./SellerPageCategoryList";
import SellerPageLocation from "./SellerPageLocation";
import SellerLiveStream from "./SellerLiveStream";
import SellerAboutControlButton from "../Controls/SellerAboutControlButton";
import StatPlate from "../Misc/StatPlate"
import { EyeSVG, HeartSVG, StarSVG } from "../../images/SVGAssets"

function AboutShop(){

    return(
        <div className="w-1/2">
            <div className="flex items-center justify-between bg-black-lidabro rounded-lg px-6 py-3">
                <div className="flex items-center gap-6">
                    <div className="w-12 h-12">
                        <img className="w-full h-full rounded-full border-2 border-white" src="/images/ellipse-12.png" alt=""/>
                    </div>
                    <div className="flex flex-col gap-1.5">
                        <h3 className="font-main font-bold text-lg text-white">Home Decor & Co</h3>
                        <SellerPageCategoryList />
                    </div>
                    
                </div>
                <div className="flex flex-col items-end gap-1.5">
                    <SellerAboutControlButton name={"Edit"}/>
                    <SellerAboutControlButton name={"Preview"} />
                </div>
            </div>
            <div className="flex flex-col p-6 gap-3 border-4 border-white rounded-lg lidabro-shop-gradient">
                <h4 className="font-secondary text-sm font-bold text-gray-lidabro-dark">About the Shop</h4>
                <p className="font-secondary font-medium text-sm mt-3 text-gray-lidabro-dark">Welcome to our shop! Explore our unique and affordable products, from fashion to home decor. Find trendy pieces and timeless classics. Shop now and discover something special for yourself or as a gift. Customer satisfaction is our priority. Happy shopping!
                <br/>
                <br/>
                Welcome to our shop! Explore our unique and affordable products, from fashion to home decor. Find trendy pieces and timeless classics. Shop now and discover something special for yourself or as a gift. Customer satisfaction is our priority. Happy shopping!</p>
                <hr className='rounded-sm border-gray-lidabro-button'/>
                <div className="flex justify-between">
                    <div className="flex flex-col gap-1.5">
                        <SellerPageLocation />
                        <SellerLiveStream />
                    </div>
                    <div className="flex items-end">
                        <StatPlate icon={<EyeSVG />} amount={255} />
                        <StatPlate icon={<HeartSVG />} amount={177} />
                        <StatPlate icon={<StarSVG />} amount={4.9} />
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default AboutShop;