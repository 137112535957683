import React, { useMemo, useState } from "react";
import CompanyLogo from "./CompanyLogo";
import {CheckmarkSVG} from "../../images/SVGAssets"
import { fetchCreateShopCurrentStep } from "../../functions/fetch";

function HeaderCreateShop(){
        function stepIsDone(step, currentStep){
        if (step < currentStep){
            return true;
        }
        return false;
    }

    function stepIsCurrentStepOrDone(step, currentStep){
        if (step <= currentStep){
            return true;
        }
        return false;
    }

    let currentStep = fetchCreateShopCurrentStep();


    return (
        <header className="container flex flex-wrap m-3 items-center justify-center bg-blue-lidabro-header rounded-lg px-6 py-4">
            <div className="flex w-full h-12">
                <div className="flex w-2/12 h-full">
                    <CompanyLogo textColor="text-white"/>
                </div>
                <div className="flex w-10/12 h-full items-center justify-evenly text-white font-bold text-base">
                    <div className="flex">
                        <div className="flex items-center justify-center w-5 h-5 bg-white mr-3 rounded-full">
                            {stepIsDone(1, currentStep) ? <CheckmarkSVG /> : <p className="text-blue-lidabro-header">1</p>}
                        </div>
                        <p>Shop's Setup</p>
                    </div>
                    <div className={`flex${stepIsCurrentStepOrDone(2, currentStep) ? "" : " opacity-50"}`}>
                        <div className="flex items-center justify-center w-5 h-5 bg-white mr-3 rounded-full">
                            {stepIsDone(2, currentStep) ? <CheckmarkSVG /> : <p className="text-blue-lidabro-header">2</p>}
                        </div>
                        <p>Choose selling categories</p>
                    </div>
                    <div className={`flex${stepIsCurrentStepOrDone(3, currentStep) ? "" : " opacity-50"}`}>
                        <div className="flex items-center justify-center w-5 h-5 bg-white mr-3 rounded-full">
                            {stepIsDone(3, currentStep) ? <CheckmarkSVG /> : <p className="text-blue-lidabro-header">3</p>}
                        </div>
                        <p>Add team members</p>
                    </div>
                    <div className={`flex${stepIsCurrentStepOrDone(4, currentStep) ? "" : " opacity-50"}`}>
                        <div className="flex items-center justify-center w-5 h-5 bg-white mr-3 rounded-full">
                            {stepIsDone(4, currentStep) ? <CheckmarkSVG /> : <p className="text-blue-lidabro-header">4</p>}
                        </div>
                        <p>Connect Bank Account</p>
                    </div>
                    <div className={`flex${stepIsCurrentStepOrDone(5, currentStep) ? "" : " opacity-50"}`}>
                        <div className="flex items-center justify-center w-5 h-5 bg-white mr-3 rounded-full">
                            {stepIsDone(5, currentStep) ? <CheckmarkSVG /> : <p className="text-blue-lidabro-header">5</p>}
                        </div>
                        <p>Ready to go!</p>
                    </div>
                </div>
            </div>
        </header>
    );
    
}

export default HeaderCreateShop;