import { DeliveryCheckmarkSVG } from "../../images/SVGAssets";

function DeliveryCheckbox({ checked, onChange, label, id }){
    function renderCheckbox(status){
        switch (status) {
            case true:
                return (
                    <div id={id} className="w-4 h-4 rounded-[4px] flex justify-center items-center bg-blue-lidabro-button" onClick={onChange}>
                        <DeliveryCheckmarkSVG />
                    </div>
                )
            case false:
                return <div id={id} className="w-4 h-4 relative bg-white rounded-[4px] border border-gray-lidabro-border-2" onClick={onChange} />
        }
    }

    return (
        <div className="flex items-center gap-2">
            {renderCheckbox(checked)}
            { label == null ? null : 
                (<p className="font-main text-sm font-medium text-gray-lidabro-dark">{label}</p>)
            }
        </div>
        
    )
}

export default DeliveryCheckbox;