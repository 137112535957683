import React from "react";
import { Link } from "react-router-dom";

function BecomePartner({token}){
        
    return (
        <div className="flex font-secondary text-sm font-medium text-white">
            <div className="px-3 py-2.5">
                <p>Become a Seller</p>
            </div>
            <div className="px-3 py-2.5">
                <p>Become a Partner</p>
            </div>
        </div>
    );
};

export default BecomePartner;