import { useState } from 'react';
import { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import { ChevronDownSelectSVG, SelectCheckboxSVG, SelectCheckboxCheckedSVG, SelectSearchIconSVG } from '../../images/SVGAssets';
import { AtributeRequired } from "./SellerCatalogHelper";

const ROW_HEIGHT = 44; // Height of a single row, adjust as needed
const MAX_ROWS = 5; // Maximum number of rows to display before showing scrollbar

const selectStyle = {
    indicatorSeparator: () => 'hidden',
    control: ({ isFocused }) =>
        classNames(
          'font-secondary',
          'text-base', 
          'font-normal',
          'leading-6',
          'border border-gray-lidabro-divider rounded-lg px-3.5 py-2.5',
          isFocused && '!border-blue-lidabro-select-focused shadow-select-shadow'
        ),
    placeholder: ({ isFocused }) => 
        classNames(
            'text-gray-lidabro-placeholder',
            isFocused && 'hidden'
        ),
    menu: () => classNames(
        'flex',
        'flex-column',
        'flex-start',
        'flex-[1_0_0]',
        'bg-white',
        'py-1',
        'px-1.5',
        'rounded-lg',
        'border',
        'border-gray-lidabro-select-menu-border'
    ),
    menuList: () => classNames(
        'w-full',
        'scrollbar',
        'scrollbar-w-2',
        'scrollbar-thumb-rounded-full', 
        'scrollbar-track-rounded-full',
        'scrollbar-thumb-gray-lidabro-select-menu-border'
    ),
    valueContainer: () => classNames(
        'text-black-lidabro-select-text',
        'font-medium',
        'gap-2'
    ),
    option: ({ isSelected }) => classNames(
        '!flex',
        'flex-row',
        'flex-[1_0_0]',
        'items-center',
        'gap-2',
        'px-2',
        'py-2.5',
        'rounded-md',
        'hover:bg-gray-lidabro-select-background',
        isSelected && 'bg-gray-lidabro-select-background'
    ),
    multiValue: () => classNames(
        'border',
        'border-gray-lidabro-border-2',
        'rounded-md',
        'py-0.5',
        'pl-[5px]',
        'pr-1',
        'text-sm',
        'text-gray-lidabro-text'
    ),
    multiValueRemove: () => classNames(
        'text-gray-lidabro-select-remove'
    )
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownSelectSVG />
        </components.DropdownIndicator>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props}>
            {props.isSelected ? <SelectCheckboxCheckedSVG /> : <SelectCheckboxSVG />}
            {props.label}
        </components.Option>
    )
}

const Control = ({ children, ...props }) => (
    <components.Control {...props}>
       <SelectSearchIconSVG />{children}
    </components.Control>
  );

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

const defaultOptions = [
    createOption('Chocolate'),
    createOption('Strawberry'),
    createOption('Vanilla'),
    createOption('Chocolate1'),
    createOption('Strawberry1'),
    createOption('Vanilla1')
  ]

function MultipleChoiceInput({atributeName, label, placeholder, required, onChange}) {
    const [options, setOptions] = useState(defaultOptions);
    const [value, setValue] = useState();

    const handleCreate = (inputValue) => {
        const newOption = createOption(inputValue);
        setOptions((prev) => [...prev, newOption]);
        setValue((prev) => [...prev, newOption]);
    };

    return (
        <div className="flex flex-col gap-1.5">
            <label htmlFor={atributeName} className="font-secondary text-base font-semibold">{label}{required ? " " : null}{required ? AtributeRequired() : null}</label>
            <CreatableSelect unstyled={true}
                    options={options}
                    placeholder={"Search"}
                    classNames={selectStyle}
                    components={{ DropdownIndicator, Option, Control }}
                    maxMenuHeight={ROW_HEIGHT * MAX_ROWS}
                    closeMenuOnSelect={false}
                    isMulti
                    hideSelectedOptions={false}
                    isClearable={false}
                    onCreateOption={handleCreate}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
            />
        </div>
    )
}

export default MultipleChoiceInput;