import { useState } from "react";
import SingleChoice from "../SellerCatalog/SingleChoice";

function SellerCatalogAddItemInfoStreamer(){
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [formValues, setFormValues] = useState({});

    function handleDescriptionChange(e){
        setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
        setDescriptionLength(e.target.value.length);
    }
    
    return (
        <div className='flex flex-col gap-3 bg-white p-6 rounded-lg'>
            <SingleChoice atributeName={"streamers"} label={"Streamers"} required={true} placeholder={"Select"} values={""} orientation={"horizontal"}/>
        </div>
    )
}

export default SellerCatalogAddItemInfoStreamer;