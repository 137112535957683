import HeaderEmptySeller from '../../../components/Header/HeaderEmptySeller'
import NavigateBack from '../../../components/Controls/Navigation/NavigateBack';
import ButtonShortTransparent from '../../../components/Controls/Buttons/ButtonShortTransparent';
import SearchBar from '../../../components/Controls/SearchBar/SearchBar';

function SellerYourCatalogAdd(){
    return (
        <>
            <HeaderEmptySeller />
            <div className='flex-grow h-full flex flex-col items-center bg-[#0C111D]/[.7]'>
                <div className='flex items-center justify-between w-full'>
                    <NavigateBack name={"Adding a new item"} callback={()=>{console.log("Navigation Back Clicked")}} bgColor={"bg-gray-lidabro-button/70"} textColor={"text-gray-lidabro-button/70"}/>
                    <ButtonShortTransparent label={"Add Manually"} callback={()=>{console.log("Add Manually Clicked")}}/>
                </div>
                <div className='w-full flex-grow flex flex-col items-center justify-center'>
                    <p className='m-11 text-center font-main text-4xl font-medium leading-[2.7rem] tracking-[-.72px] lidabro-text-gradient'>Tell us what are you selling?</p>
                    <div className='w-1/2'>
                        <SearchBar placeholder={"Search through bar codes, enter Category Name or Product’s Name"}/>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default SellerYourCatalogAdd;