export const catalogChildren = [
    {
        name: 'In Stock',
        callback: ()=> {redirect('/catalog/in-stock')},
        selected: true
    },
    {
        name: 'Out of Stock',
        callback: ()=> {redirect('/catalog/out-of-stock')},
        selected: false
    },
    {
        name: 'Drafts',
        callback: ()=> {redirect('/catalog/drafts')},
        selected: false
    }
]

export const teamChildren = [
        {
            name: 'All',
            callback: ()=> {redirect('/team/all')},
            selected: true
        },
        {
            name: 'Streamers',
            callback: ()=> {redirect('/team/streamers')},
            selected: false
        },
]

export const streamManagementChildren = [
        {
            name: 'Overview',
            callback: ()=> {redirect('/stream-management/overview')},
            selected: true
        },
        {
            name: 'Sessions',
            callback: ()=> {redirect('/stream-management/sessions')},
            selected: false
        },
        {
            name: 'Settings',
            callback: ()=> {redirect('/stream-management/settings')},
            selected: false
        },
]

export const ordersChildren = [
    {
        name: 'All',
        callback: ()=> {redirect('/orders/all')},
        selected: true
    },
    {
        name: 'All',
        callback: ()=> {redirect('/orders/all')},
        selected: false
    },
]

export const promotionsChildren = [
    {
        name: 'All',
        callback: ()=> {redirect('/promotions/all')},
        selected: true
    },
    {
        name: 'All',
        callback: ()=> {redirect('/promotions/all')},
        selected: false
    },
]

export const settingsChildren = [
    {
        name: 'All',
        callback: ()=> {redirect('/settings/all')},
        selected: true
    },
    {
        name: 'All',
        callback: ()=> {redirect('/settings/all')},
        selected: false
    },
]