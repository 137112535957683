import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchItemByName } from "../../functions/fetch";
import {ChevronDownSVG, SearchSVG} from "../../images/SVGAssets"

function SearchBar({placeholderText}) {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if (query) {
			const abortController = new AbortController();
			fetchItemByName(query, abortController.signal).then((movies) => {
				if (movies) {
					setResults(movies.results);
				}
			});
			return () => {
				abortController.abort();
			};
		}
	}, [query]);

	const handleSubmit = useCallback(
		async (name) => {
			const movie = await fetchItemByName(name);
			navigate(`/library/${movie.results[0].id}`);
		},
		[navigate]
	);

	return (
		<div className="flex flex-1 w-auto max-w-[500px] h-11 px-5 items-center rounded-lg bg-gray-lidabro-light text-gray-lidabro-dark">
			<SearchSVG />
			<input
				className="w-full h-11 p-2 outline-none bg-inherit text-inherit"
				type="search"
				name=""
				id="search"
				list="result"
				placeholder={placeholderText}
				autoComplete="off"
				value={query}
			/>
		</div>
	);
}

export default SearchBar;
