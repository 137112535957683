import AboutShop from "../../../components/Seller/AboutShop";
import Reviews from "../../../components/Seller/Reviews";
import SalesCarousel from "../../../components/Seller/SalesCarousel";


function SellerYourShop() {

    return (
        <div className="flex flex-col gap-6">
            <div className="">
                <h3 className="font-main text-2xl">Your Shop</h3>
            </div>
            <div className="flex gap-6">
                <AboutShop />
                <Reviews /> 
            </div>
            <div>
                <SalesCarousel />
            </div>
        </div>
    );
}

export default SellerYourShop;