import ListSorter from "./ListSorter";
import ListCheckbox from "./ListCheckbox";

function ListHeader(props){
    
    
    return (
        <div className={`flex px-3 items-center justify-between ${props.bgColor}`}>
            <div className="flex">
                <div className="">
                    <ListCheckbox name={`row-header`} label={'1-10 from 120'} callback={props.setSelectAll}/>
                </div>
            </div>
            <div className="flex items-center pr-9">
                {
                    props.amount == null ? (null) :
                        (
                            <div className="px-3 py-3">
                                <ListSorter name={"Amount"} />
                            </div>
                        ) 
                }
                {
                    props.price == null ? (null) :
                        (
                            <div className="px-3 py-3">
                                <ListSorter name={"Price"} />
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default ListHeader;