import { Outlet } from 'react-router-dom';
import HeaderCreateShop from '../../../components/Header/HeaderCreateShop';

function SellerCreateShop(){
    
    return (
        <>
            <HeaderCreateShop />
            <div className='container flex items-center justify-center mt-20'>
                <Outlet />
            </div>
        </>
    );
}


export default SellerCreateShop;