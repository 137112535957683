import { ChevronRightSVG } from "../../images/SVGAssets";

function Breadcrumb({label, last}) {

    return (
        <>
            <p className={`px-2 py-1 cursor-pointer${last ? ' text-gray-lidabro-breadcrumb-bold font-semibold' : ''}`}>{label}</p>
            {!last ? <ChevronRightSVG /> : null}
        </>
    )
}

export default Breadcrumb;