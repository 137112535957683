import React from "react";
import '../../styles/Location.css';
import {MapPinSVG} from "../../images/SVGAssets";

function Location(){
    return (
        <div className="flex gap-3 px-2.5 py-3 font-secondary text-sm font-medium items-center">
            <MapPinSVG />
            <p>Tel Aviv-Yafo, Israel</p>
        </div>
    );
};

export default Location;