import { ArrowLeftSVG } from "../../../images/SVGAssets";

function NavigateBack({name, callback, bgColor, textColor}) {
    return (
        <div className='flex self-start items-center gap-3 ps-12 pe-3 py-6'>
            <div className={`${bgColor} px-3 py-2.5 rounded-lg`} onClick={callback}>
                <ArrowLeftSVG/>
            </div>
            <p className={`${textColor} font-main text-2xl font-medium`}>{name}</p>
        </div>
    )    
}

export default NavigateBack;